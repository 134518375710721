<template>
	<div class="activityTable">
		<div class="activityTable_tab">
			<div @click="handleForm('add')" class="knowledgeBase_add_btn">
				+添加夏校
			</div>
		</div>
		<div class="activityTable_content">
			<div class="knowledgeBase_table">
				<div class="knowledgeBase_table_box">
					<el-table class="knowledgeBase_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
						:data="tableData" :border="false">
						<el-table-column width="80px" type="index" label="序号" :index="indexAdd">
						</el-table-column>
						<el-table-column prop="cover" label="封面">
							<template slot-scope="scope">
								<div class="knowledgeBase_table_cover">
									<el-image 
										class="table-image"
										:src="scope.row.cover" 
										:preview-src-list="scope.row.imgSrc">
									</el-image>
									<!-- <img :src="scope.row.cover"> -->
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="title" label="标题">
						</el-table-column>
						<el-table-column prop="content" label="内容">
							<template slot-scope="scope">
								<el-tooltip popper-class="tooltip_bg" class="item" effect="dark" placement="top">
									<div class="tooltip_inner" slot="content" v-html="scope.row.content"></div>
									<div v-html="scope.row.content" class="knowledgeBase_table_content">
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column prop="createTime" label="发布时间">
						</el-table-column>
						<el-table-column width="150px" prop="action" label="操作">
							<template slot-scope="scope">
								<div class="knowledgeBase_table_action">
									<span @click="handleForm('edit',scope.row)">编辑</span>
									<span @click="handleForm('delete',scope.row)">删除</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="knowledgeBase_table_page">
					<el-pagination @current-change="currentChange" :current-page.sync="pageIndex" :page-size="pageSize"
						layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege" :isOpen="messegeVisible" :messegeTip="messegeTip"
			:messegeContent="messegeContent" :messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		listSummer,
		delSummer
	} from "@/api/knowledgeBase"
	export default {
		components: {},
		data() {
			return {
				pageIndex: 1,
				pageSize: 10,
				totalCount: 0,
				tableData: [],
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				id:""
			}
		},
		created() {
			this.summerList()
		},
		filters:{
			ellipsis(value) {
				value = value.replace(/<.*?>/ig,"")       //把v-html的格式标签替换掉
				return value
			}
		},
		methods: {
			headStyle() {
				return 'text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;'
			},
			rowStyle() {
				return 'text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;'
			},
			closeMessege() {
				this.messegeVisible = false;
			},
			confirmMessege(){
				this.summerDelete(this.id)
			},
			indexAdd(index){
				const page = this.pageIndex;
				const pageSize = this.pageSize;
				return index + 1 + (page-1) * pageSize
			},
			summerDelete(){
				delSummer(this.id).then(res=>{
					if(res.code == 0){
						this.$message({
							message: "夏校库删除成功~",
							type: 'success'
						})
						this.messegeVisible = false;
						this.pageIndex = 1;
						this.summerList()
					}
				})
			},
			// 获取夏校库列表
			summerList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				listSummer(params).then((res) => {
					if(res.data&&res.data.length>0){
						res.data.forEach(el=>{
							if(el.cover){
								el.imgSrc = []
								el.imgSrc.push(el.cover)
							}
						})
					}
					this.tableData = res.data
					this.totalCount = res.page.totalCount * 1
				})
			},
			// 夏校库分页
			currentChange(val) {
				this.pageIndex = val
				this.summerList()
			},
			handleForm(type,row) {
				switch (type) {
					case "add":
						this.$emit('handleSummer', {
							type: 'add'
						})
						break;
					case "edit":
						this.$emit('handleSummer', {
							type: 'edit',
							schoolId:row.id
						})
						break;
					case "delete":
						this.messegeTip = "删除夏校";
						this.messegeContent = "确定删除此夏校吗?";
						this.messegeVisible = true;
						this.messegeType = "warn";
						this.id = row.id
						break;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	@import "./common.scss";
	.knowledgeBase_table_cover{
		// width:240px;
		cursor: pointer;
		.table-image{
			width:240px;
			// height:100%;
		}
	}
	.activityTable {
		display: flex;
		flex-direction: column;

		.activityTable_tab {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.activityTable_content {
			margin-top: 16px;
		}
	}
	.tooltip-content{
		width:300px;
	}
	.knowledgeBase_table_content{
		display: -webkit-box;
		text-overflow: ellipsis;
		overflow: hidden;
		line-clamp: 2;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
