<template>
	<div class="schoolTable">
		<div class="schoolTable_search">
			<div class="schoolTable_search_value">
				<div class="handle_form">
					<el-form class="flex_item" ref="searchForm" :model="searchForm" label-width="70px" size="mini">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="学校名称:">
									<input v-model="searchForm.name" placeholder="请输入学校名称"
										class="handle_form_ipt"></input>
									<!-- <el-autocomplete style="width:300px" v-model="searchForm.name"
										:fetch-suggestions="querySearchSchool" placeholder="请输入" @select="selectSchool">
									</el-autocomplete> -->
									<!-- <el-select v-model="searchForm.name" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in schoolName" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select> -->
								</el-form-item>
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="学校性质:">
									<el-select v-model="searchForm.nature" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in schoolNatureSearch" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="学校类型:">
									<el-select v-model="searchForm.type" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in schoolTypeSearch" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
					</el-form>
				</div>
				<div class="handle_search">
					<span @click="handleForm('search')" class="handle_search_confirm">查询</span>
					<span @click="handleForm('reset')" class="handle_search_cancel">重置</span>
				</div>
			</div>
			<div @click="handleForm('add')" class="knowledgeBase_add_btn">
				+添加学校
			</div>
		</div>
		<div class="schoolTable_content">
			<div class="knowledgeBase_table">
				<div class="knowledgeBase_table_box">
					<el-table class="knowledgeBase_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
						:data="tableData" :border="false">
						<el-table-column width="80px" type="index" label="序号" fixed :index="indexAdd">
						</el-table-column>
						<el-table-column width="220px" prop="name" label="学校名称" fixed>
							<template slot-scope="scope">
								<div class="ranking">
									{{scope.row.ranking}}
								</div>
								<div class="schoolname">
									{{scope.row.name}}
								</div>
								<div class="schoolname">
									{{scope.row.enName}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="logo" label="学校LOGO">
							<template slot-scope="scope">
								<div class="knowledgeBase_table_logo">
									<el-image fit="cover" class="el-image" :src="scope.row.logo"
										:preview-src-list="scope.row.imgSrc">
									</el-image>
									<!-- <img :src="scope.row.logo"> -->
								</div>
							</template>
						</el-table-column>
						<el-table-column width="150px" prop="logo" label="学校简介">
							<template slot-scope="scope">
								<el-tooltip popper-class="tooltip_bg" class="item" effect="dark"
									:content="scope.row.introduction" placement="top-start">
									<div class="knowledgeBase_table_introduction">
										{{scope.row.introduction}}
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column prop="nature" label="学校性质">
						</el-table-column>
						<el-table-column prop="logo" label="本科批次">
							<template slot-scope="scope">
								<div class="">
									{{scope.row.undergraduateBatch}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="logo" label="转学批次">
							<template slot-scope="scope">
								<div class="">
									{{scope.row.transferBatch}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="structure" label="学校构成">
						</el-table-column>
						<el-table-column prop="type" label="学校类型">
						</el-table-column>
						<el-table-column prop="number" label="在校学生">
						</el-table-column>
						<el-table-column prop="country" label="所在国家">
						</el-table-column>
						<el-table-column prop="environment" label="所在环境">
						</el-table-column>
						<el-table-column prop="hiringrate" label="录取率">
						</el-table-column>
						<el-table-column width="250px" prop="applysystem" label="申请系统">
						</el-table-column>
						<el-table-column width="250px" prop="officialurl" label="学校官网">
						</el-table-column>
						<el-table-column width="250px" prop="address" label="学校地址">
						</el-table-column>
						<el-table-column prop="cost" label="费用">
						</el-table-column>
						<el-table-column width="150px" prop="action" label="操作">
							<template slot-scope="scope">
								<div class="knowledgeBase_table_action">
									<span @click="handleForm('edit', scope)">编辑</span>
									<span @click="handleForm('delete', scope)">删除</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="knowledgeBase_table_page">
					<el-pagination @current-change="currentChange" :current-page.sync="pageIndex" :page-size="pageSize"
						layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<div class="schoolTable_dialog">
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeSchoolDialog" width="597px" :title="diaLogSchoolTitle"
				:visible.sync="diaLogSchoolVisible">
				<div class="handle_form">
					<el-form ref="schoolDetail" :rules="schoolRules" :model="schoolDetail" label-width="110px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="name" class="flex_item" label="学校名称(中文):">
									<input v-model="schoolDetail.name" placeholder="请输入学校名称"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="enName" class="flex_item" label="学校名称(英文):">
									<input v-model="schoolDetail.enName" placeholder="请输入学校名称"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="ranking" class="flex_item" label="学校排名:">
									<input v-model="schoolDetail.ranking" placeholder="请输入学校排名"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" prop="logo" label="学校LOGO:">
									<div>
										<div style="margin-bottom: 6px;">
											<dragUpLoad ref="dragUpLoad" picType="school" :isLimitPx="true" @beforeUpload="beforeUpload" @removeImg="removeImg" />
										</div>
									    <span style="color: #888888;">注：图片宽高限制最小为（80x80），其他格式请保持宽高比例为1:1，否则无法上传！</span>
									</div>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="introduction" class="" label="学校简介:">
									<textarea v-model="schoolDetail.introduction" placeholder="请输入学校简介"
										class="handle_form_textarea"></textarea>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="nature" class="flex_item" label="学校性质:">
									<el-select v-model="schoolDetail.nature" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in schoolNature" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="undergraduateBatch" class="flex_item" label="本科批次:">
									<input v-model="schoolDetail.undergraduateBatch" placeholder="请输入本科批次"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="transferBatch" class="flex_item" label="转学批次:">
									<input v-model="schoolDetail.transferBatch" placeholder="请输入转学批次"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="structure" class="flex_item" label="学校构成:">
									<el-select v-model="schoolDetail.structure" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in schoolStructure" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="type" class="flex_item" label="学校类型:">
									<el-select v-model="schoolDetail.type" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in schoolTypeDia" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="在校学生:">
									<input v-model="schoolDetail.number" placeholder="请输入在校学生"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="country" class="flex_item" label="所在国家:">
									<el-select v-model="schoolDetail.country" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in schoolCountry" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="environment" class="flex_item" label="所在环境:">
									<el-select v-model="schoolDetail.environment" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in schoolEnvironment" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="录取率:">
									<input v-model="schoolDetail.hiringrate" placeholder="请输入录取率"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="申请系统:">
									<input v-model="schoolDetail.applysystem" placeholder="请输入申请系统"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="学校官网:">
									<input v-model="schoolDetail.officialurl" placeholder="请输入学校官网"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="学校地址:">
									<input v-model="schoolDetail.address" placeholder="请输入学校地址"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="费用:">
									<input v-model="schoolDetail.cost" placeholder="请输入费用"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeSchoolDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="handleForm('submit')" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
import { listSchool, addSchool, deleteSchool } from "@/api/knowledgeBase";
import dragUpLoad from "@/components/dragUpLoad/index.vue";
export default {
  components: {
    dragUpLoad
  },
  data() {
    const checkLogo = (rule, value, callback) => {
      if (this.schoolDetail.logo == "") {
        callback(new Error("请上传学校LOGO"));
      } else {
        callback();
      }
      callback();
    };
    const checkNum = (rule, value, callback) => {
      let regxNum = /^[0-9]*$/;
      if (!value) {
        callback(new Error("请输入学校排名"));
      } else {
        if (!regxNum.test(value)) {
          callback(new Error("请输入正确学校排名"));
        } else {
          callback();
        }
      }
      callback();
    };
    return {
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      searchForm: {},
      schoolName: [],
      schoolNatureSearch: [
        {
          label: "所有",
          value: ""
        },
        {
          label: "综合性大学",
          value: "综合性大学"
        },
        {
          label: "文理学院",
          value: "文理学院"
        },
        {
          label: "非排名学校",
          value: "非排名学校"
        }
      ],
      schoolNature: [
        {
          label: "综合性大学",
          value: "综合性大学"
        },
        {
          label: "文理学院",
          value: "文理学院"
        },
        {
          label: "非排名学校",
          value: "非排名学校"
        }
      ],
      schoolStructure: [
        // {
        // 	label: "所有",
        // 	value: "所有"
        // },
        {
          label: "男女学校",
          value: "男女学校"
        },
        {
          label: "女子学院",
          value: "女子学院"
        },
        {
          label: "男子学院",
          value: "男子学院"
        }
      ],
      schoolTypeSearch: [
        {
          label: "所有",
          value: ""
        },
        {
          label: "公立",
          value: "公立"
        },
        {
          label: "私立",
          value: "私立"
        }
      ],
      schoolTypeDia: [
        {
          label: "公立",
          value: "公立"
        },
        {
          label: "私立",
          value: "私立"
        }
      ],
      schoolCountry: [
        // {
        // 	label: "所有",
        // 	value: "所有"
        // },
        {
          label: "美国",
          value: "美国"
        },
        {
          label: "英国",
          value: "英国"
        },
        {
          label: "加拿大",
          value: "加拿大"
        },
        {
          label: "其他",
          value: "其他"
        }
      ],
      schoolEnvironment: [
        // {
        // 	label: "所有",
        // 	value: "所有"
        // },
        {
          label: "城市",
          value: "城市"
        },
        {
          label: "城郊",
          value: "城郊"
        },
        {
          label: "乡村",
          value: "乡村"
        }
      ],
      tableData: [],
      messegeVisible: false,
      messegeTip: "",
      messegeContent: "",
      messegeType: "",
      diaLogSchoolTitle: "",
      diaLogSchoolVisible: false,
      schoolRules: {
        name: [
          {
            required: true,
            message: "请输入中文学校名称",
            trigger: "change"
          }
        ],
        enName: [
          {
            required: true,
            message: "请输入英文学校名称",
            trigger: "change"
          }
        ],
        ranking: [
          {
            required: true,
            validator: checkNum,
            trigger: "change"
          }
        ],
        name: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: "change"
          }
        ],
        logo: [
          {
            required: true,
            validator: checkLogo,
            trigger: "change"
          }
        ],
        introduction: [
          {
            required: true,
            message: "请输入学校简介",
            trigger: "change"
          }
        ],
        nature: [
          {
            required: true,
            message: "请选择学校性质",
            trigger: "blur"
          }
        ],
        undergraduateBatch: [
          {
            required: true,
            message: "学校批次(本科)",
            trigger: "change"
          }
        ],
        transferBatch: [
          {
            required: true,
            message: "学校批次(转学)",
            trigger: "change"
          }
        ],
        structure: [
          {
            required: true,
            message: "请选择学校构成",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "请选择学校类型",
            trigger: "blur"
          }
        ],
        country: [
          {
            required: true,
            message: "请选择所在国家",
            trigger: "blur"
          }
        ],
        environment: [
          {
            required: true,
            message: "请选择所在环境",
            trigger: "blur"
          }
        ]
      },
      schoolDetail: {},
      schoolId: "",
      schoolLogo: []
    };
  },
  watch: {
    diaLogSchoolVisible: {
      handler(val) {
        if (!val) {
          this.$nextTick(() => {
            this.$refs.schoolDetail.resetFields();
            this.$refs.schoolDetail.clearValidate();
          });
          this.avatarUrlList = [];
        }
      },
      deep: true
    }
  },
  created() {
    this.schoolList();
    // this.getSchoolName()
  },
  methods: {
    headStyle() {
      return "text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;";
    },
    rowStyle() {
      return "text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;";
    },
    closeMessege() {
      this.messegeVisible = false;
    },
    indexAdd(index) {
      const page = this.pageIndex;
      const pageSize = this.pageSize;
      return index + 1 + (page - 1) * pageSize;
    },
    confirmMessege() {
      deleteSchool({
        id: this.schoolId
      }).then(res => {
        if (res.code == 0) {
          this.$message({
            message: "学校删除成功~",
            type: "success"
          });
          this.pageIndex = 1;
          this.schoolList();
          this.messegeVisible = false;
        }
      });
    },
    closeSchoolDialog() {
      this.diaLogSchoolVisible = false;
    },
    beforeUpload(props) {
      if (props.length > 0) {
        this.schoolDetail.logo = props[0];
      } else {
        this.schoolDetail.logo = "";
      }
    },
    removeImg(props) {
      if (props.length > 0) {
        this.schoolDetail.logo = props[0];
      } else {
        this.schoolDetail.logo = "";
      }
    },
    // 获取学校库
    schoolList() {
      let params = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
        name: this.searchForm.name,
        nature: this.searchForm.nature,
        type: this.searchForm.type
      };
      listSchool(params).then(res => {
        if (res.data.records && res.data.records.length > 0) {
          res.data.records.forEach(el => {
            if (el.logo) {
              el.imgSrc = [];
              el.imgSrc.push(el.logo);
            }
          });
        }
        this.tableData = res.data.records;
        this.totalCount = res.data.total * 1;
      });
    },
    selectSchool(item) {
      // console.log(item);
      this.searchForm.name = item.address;
      this.$forceUpdate();
    },
    // 搜索学校
    async querySearchSchool(queryString, cb) {
      let params = {
        pageIndex: 1,
        pageSize: 10,
        name: queryString
      };
      let restaurants = await this.getSchoolName(params);
      let newList = [];
      restaurants.forEach(item => {
        newList.push({
          value: item.name,
          address: item.name
        });
      });
      console.log("newList", newList);
      let results = queryString
        ? newList.filter(this.createStateFilter(queryString))
        : newList;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 1300 * Math.random());
    },
    createStateFilter(queryString) {
      return state => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    // 获取学校名称
    getSchoolName(params) {
      return new Promise((resolve, reject) => {
        listSchool(params).then(res => {
          if (res.data.records.length > 0) {
            // this.schoolName = res.data.records.map((item) => {
            // 	return {
            // 		label: item.name,
            // 		value: item.name
            // 	}
            // })
            resolve(res.data.records);
          } else {
            resolve([]);
          }
        });
      });
    },
    // 学校库分页
    currentChange(val) {
      this.pageIndex = val;
      this.schoolList();
    },
    handleForm(type, scope) {
      switch (type) {
        case "add":
          this.schoolDetail = {};
          this.$nextTick(() => {
            this.$refs.dragUpLoad.fileData = [];
          });
          this.diaLogSchoolTitle = "添加学校";
          this.diaLogSchoolVisible = true;
          break;
        case "edit":
          let detail = JSON.parse(JSON.stringify(scope.row));
          this.schoolDetail = detail;
          this.diaLogSchoolTitle = "编辑学校";
          this.diaLogSchoolVisible = true;
          this.$nextTick(() => {
            this.$refs.dragUpLoad.fileData = [detail.logo];
          });
          break;
        case "delete":
          this.schoolId = scope.row.id;
          this.messegeTip = "删除学校";
          this.messegeContent = "确定删除此学校吗?";
          this.messegeVisible = true;
          this.messegeType = "warn";
          break;
        case "search":
          this.pageIndex = 1;
          this.schoolList();
          break;
        case "reset":
          this.searchForm = {};
          this.pageIndex = 1;
          this.schoolList();
          break;
        case "submit":
          this.$refs["schoolDetail"].validate(valid => {
            if (valid) {
              let schoolForm = JSON.parse(JSON.stringify(this.schoolDetail));
              console.log("添加学校", schoolForm);
              let messege = "";
              if (this.diaLogSchoolTitle == "添加学校") {
                schoolForm.id = 0;
                messege = "学校添加成功~";
              } else {
                messege = "学校编辑成功~";
              }
              addSchool(schoolForm).then(res => {
                if (res.code == 0) {
                  this.$message({
                    message: messege,
                    type: "success"
                  });
                  this.pageIndex = 1;
                  this.schoolList();
                  this.diaLogSchoolVisible = false;
                }
              });
            }
          });
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";
@import "./common.scss";

.knowledgeBase_table_logo {
  cursor: pointer;

  // width:100%;
  // height:100%;
  .el-image {
    // height:100%;
    // width:100%;
    width: 100%;
    height: 100px;
  }
}

.ranking {
  min-width: 20px;
  min-height: 20px;
  line-height: 20px;
  text-align: center;
  background: #e6e6e6;
  position: absolute;
  top: 0;
}

.schoolname {
	font-size: 12px;
}

/deep/.el-input__inner {
  height: 36px;
}

/deep/.schoolTable_dialog .el-dialog__body {
  max-height: 700px;
  overflow: hidden;
  overflow-y: auto;
}

.schoolTable {
  display: flex;
  flex-direction: column;

  .schoolTable_search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .schoolTable_search_value {
      display: flex;

      .handle_form_select {
        width: 320px;
      }

      .handle_search {
        display: flex;
        align-items: center;

        .handle_search_confirm {
          width: 92px;
          line-height: 36px;
          background: $theme_color;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
          margin-right: 16px;
        }

        .handle_search_cancel {
          width: 92px;
          line-height: 36px;
          border: 1px solid $theme_color;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: $theme_color;
          cursor: pointer;
        }
      }
    }
  }

  .schoolTable_content {
  }

  .schoolTable_dialog {
    .handle_form_row {
      margin-top: 25px;
    }
  }
}
</style>
