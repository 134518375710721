<template>
	<div class="activityTable">
		<div class="activityTable_tab">
			<tab-list @changeViews="changeViews" :tabList="activityTabList" :currentTab="currentActivity" />
			<div @click="handleForm('add')" class="knowledgeBase_add_btn">
				+添加活动
			</div>
		</div>
		<div class="activityTable_content">
			<div class="knowledgeBase_table">
				<div class="knowledgeBase_table_box">
					<el-table class="knowledgeBase_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
						:data="tableData" :border="false">
						<el-table-column width="80px" type="index" label="序号" :index="indexAdd">
						</el-table-column>
						<el-table-column prop="logo" label="活动封面图片">
							<template slot-scope="scope">
								<div class="knowledgeBase_table_cover">
									<el-image 
										class="el-image"
										:src="scope.row.cover" 
										:preview-src-list="scope.row.imgSrc">
									</el-image>
									<!-- <img :src="scope.row.cover"> -->
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="title" label="标题">
						</el-table-column>
						<el-table-column prop="content" label="详情">
							<template slot-scope="scope">
								<el-tooltip popper-class="tooltip_bg" class="item" effect="dark" placement="top">
									<div class="tooltip_inner" slot="content" v-html="scope.row.content"></div>
									<div v-html="scope.row.content" class="knowledgeBase_table_content">
									</div>
								</el-tooltip>
								<!-- <div class="" v-html="scope.row.content"></div> -->
							</template>
						</el-table-column>
						<el-table-column label="活动类型" prop="_activityType">
						</el-table-column>
						<el-table-column prop="createTime" label="发布时间">
						</el-table-column>
						<el-table-column width="200px" prop="action" label="操作">
							<template slot-scope="scope">
								<div class="knowledgeBase_table_action">
									<span @click="handleForm('edit',scope.row)">编辑</span>
									<span @click="handleForm('delete',scope.row)">删除</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="knowledgeBase_table_page">
					<el-pagination  @current-change="currentChange" 
						:current-page.sync="pageIndex" :page-size="pageSize" layout="total, prev, pager, next"
						:total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege" :isOpen="messegeVisible" :messegeTip="messegeTip"
			:messegeContent="messegeContent" :messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		listActivity,
		delActivity
	} from "@/api/knowledgeBase"
	import tabList from "@/components/tabList/index.vue";
	import storage from 'store'
	export default {
		components: {
			tabList,
		},
		data() {
			return {
				currentActivity: 'STEM',
				activityTabList: [{
						label: 'STEM',
						value: "STEM"
					},
					{
						label: '人文社科',
						value: "HUMANITIES"
					},
					{
						label: '商业',
						value: "COMMERCIAL"
					},
					{
						label: '志愿者',
						value: "VOLUNTEER"
					}, {
						label: '其他',
						value: "OTHERS"
					},
				],
				pageIndex: 1,
				pageSize: 10,
				totalCount: 0,
				tableData: [],
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				delId:''
			}
		},
		created() {
			if(storage.get("currentTab")){
				this.currentActivity = storage.get("currentTab")
			}
			this.activityList()
		},
		methods: {
			headStyle() {
				return 'text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;'
			},
			rowStyle() {
				return 'text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;'
			},
			changeViews(item) {
				this.currentActivity = item.value
				storage.set("currentTab", item.value);
				this.activityList()
			},
			closeMessege() {
				this.messegeVisible = false;
			},
			indexAdd(index){
				const page = this.pageIndex;
				const pageSize = this.pageSize;
				return index + 1 + (page-1) * pageSize
			},
			// 获取活动库列表
			activityList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					activityType: this.currentActivity
				}
				listActivity(params).then((res) => {
					res.data.forEach(el=>{
						switch(el.activityType){
							case "STEM":
							el._activityType = "STEM"
							break;
							case "HUMANITIES":
							el._activityType = "人文社科"
							break;
							case "COMMERCIAL":
							el._activityType = "商业"
							break;
							case "VOLUNTEER":
							el._activityType = "志愿者"
							break;
							case "OTHERS":
							el._activityType = "其他"
							break;
						}
					})
					if(res.data&&res.data.length>0){
						res.data.forEach(el=>{
							if(el.cover){
								el.imgSrc = []
								el.imgSrc.push(el.cover)
							}
						})
					}
					this.tableData = res.data
					this.totalCount = res.page.totalCount * 1
				})
			},
			activityDelete(id){
				delActivity(id).then(res=>{
					if(res.code == 0){
						this.$message({
							message: "夏校库删除成功~",
							type: 'success'
						})
						this.messegeVisible = false;
						this.pageIndex = 1;
						this.activityList()
					}
				})
			},
			confirmMessege(){
				this.activityDelete(this.delId)
			},
			// 活动库分页
			currentChange(val) {
				this.pageIndex = val
				this.activityList()
			},
			handleForm(type,row) {
				switch (type) {
					case "add":
						this.$emit('handleActivity', {
							type: 'add'
						})
						break;
					case "edit":
						this.$emit('handleActivity', {
							type: 'edit',
							activityId: row.id
						})
						break;
					case "delete":
						this.messegeTip = "删除活动";
						this.messegeContent = "确定删除此活动吗?";
						this.messegeVisible = true;
						this.messegeType = "warn";
						this.delId = row.id
						break;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	@import "./common.scss";
	.knowledgeBase_table_cover{
		cursor: pointer;
		// height:123px;
		.el-image{
			width:240px;
		}
	}
	.activityTable {
		display: flex;
		flex-direction: column;

		.activityTable_tab {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.activityTable_content {
			margin-top: 16px;
		}
	}
	.knowledgeBase_table_content{
		display: -webkit-box;
		text-overflow: ellipsis;
		overflow: hidden;
		line-clamp: 2;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
