<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2022-02-25 11:52:32
-->
<template>
	<div class="knowledgeBase">
		<div class="knowledgeBase_tab">
			<module-list :moduleList="moduleList" :currentTab="currentTab" @changeTab="changeTab" />
		</div>
		<div class="knowledgeBase_content">
			<!-- 学校库 -->
			<school-table v-if="currentTab == '学校库'" />
			<!-- 活动库 -->
			<activity-table v-if="currentTab == '活动库'" @handleActivity="handleActivity" />
			<!-- 夏校库 -->
			<summer-table v-if="currentTab == '夏校库'"  @handleSummer="handleSummer"/>
		</div>
	</div>
</template>
<script>
	import moduleList from "@/components/moduleList/index.vue";
	import schoolTable from "./components/schoolTable.vue";
	import activityTable from "./components/activityTable.vue";
	import summerTable from "./components/summerTable.vue";
	import storage from 'store';
	import commonFn from "@/utils/common.js";
	export default {
		components: {
			moduleList,
			schoolTable,
			activityTable,
			summerTable,
		},
		data() {
			return {
				currentTab: '1',
				moduleList: [
					// {
					// 	label: "学校库",
					// 	value: '1'
					// },
					// {
					// 	label: "活动库",
					// 	value: '2'
					// },
					// {
					// 	label: "夏校库",
					// 	value: '3'
					// },
				]
			}
		},
		created() {
			// console.log("学生信息路由>>>>", this.$route)
			this.moduleList = []
			if(this.$route.meta.secondaryMenu) {
				let secondaryMenu = this.$route.meta.secondaryMenu
				this.moduleList = commonFn.secondaryMenu(secondaryMenu)
				// this.currentTab = this.moduleList[0].label
				if (this.$route.query.currentTab) {
					this.currentTab = this.$route.query.currentTab;
				} else {
					if (storage.get('currentTabOne')) {
						this.currentTab = storage.get('currentTabOne')
					} else {
						this.currentTab = this.moduleList[0].label
					}
				}
			}
		},
		destroyed() {
			// console.log("beforeDestroy")
			storage.remove('currentTabOne')
		},
		methods: {
			changeTab(props) {
				this.currentTab = props.label;
				storage.remove("currentTab");
				this.$router.push({ query: {} });
				storage.set('currentTabOne', this.currentTab, 7 * 24 * 60 * 60 * 1000)
			},
			handleActivity(props) {
				console.log("handleActivity", props)
				storage.set("moduleList", this.moduleList)
				let handleType = props.type
				let activityId = props.activityId
				switch (handleType) {
					case "add":
						this.$router.push({
							path: `/knowledgeBase/activity?visitType=add&&currentTab=${this.currentTab}`
						})
						break;
					case "edit":
						this.$router.push({
							path: `/knowledgeBase/activity?visitType=edit&&currentTab=${this.currentTab}&&activityId=${activityId}`
						})
						break;
				}
			},
			handleSummer(props) {
				let handleType = props.type
				switch (handleType) {
					case "add":
						this.$router.push({
							path: `/knowledgeBase/summer?visitType=add&&currentTab=${this.currentTab}`
						})
						break;
					case "edit":
						this.$router.push({
							path: `/knowledgeBase/summer?visitType=edit&&currentTab=${this.currentTab}&&schoolId=${props.schoolId}`
						})
						break;
				}
			}
		},
	}
</script>
<style lang="scss" scoped>
	@import "../../styles/index.scss";

	.flex_item {
		display: flex;
		align-items: center;
	}

	.knowledgeBase {
		display: flex;
		flex-direction: column;

		.knowledgeBase_tab {
			padding-left: 932px;
			background-color: #F7F7F7;
			position: relative;
			margin-top: 4px;

			.knowledgeBase_back {
				position: absolute;
				left: 0;
				top: 11px;
				width: 100px;
				height: 34px;
				background: #FFFFFF;
				box-shadow: 0px 3px 6px rgba(153, 153, 153, 0.16);
				border-radius: 0px 17px 17px 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				img {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}

				span {
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #333333;
				}
			}

			.knowledgeBase_back :hover {
				color: $theme_color;
			}
		}

		.knowledgeBase_content {
			background-color: #ffffff;
			padding: 20px 40px;
		}
	}
</style>
